import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap, take, filter } from 'rxjs/operators';
import { TokenService } from 'src/app/services/token/token.service'; // Adjust the path as needed
import { Router } from '@angular/router';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private tokenService: TokenService,
    private router: Router
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.withCredentials) {
      const token = this.tokenService.getTokenValue();
      if (this.isTokenExpiringSoon() && token) {
        return this.handleTokenRefresh(req, next);
      } else {
        req = this.addAuthHeader(req, token);
        return next.handle(req);
      }
    }
    else {
      return next.handle(req);
    }
  }

  private addAuthHeader(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    if (token) {
      return request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
      });
    }
    return request;
  }

  private isTokenExpiringSoon(): boolean {
    const currentTime = new Date().getTime();
    const expiryTimestamp = this.tokenService.getExpiryTimestampValue();
    return expiryTimestamp ? expiryTimestamp <= (currentTime + 10 * 60 * 1000) : true; // 10 minutes buffer
  }

  private handleTokenRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.tokenService.getNewAccessToken().pipe(
        switchMap((response) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.access_token);
          req = this.addAuthHeader(req, response.access_token);
          return next.handle(req);
        }),
        catchError((err) => {
          this.isRefreshing = false;
          this.tokenService.routeToBase();
          return throwError(err);
        })
      );
    } else {
      // Wait for the token refresh to complete
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null), // Wait until we have a non-null token
        take(1),
        switchMap((token) => {
          req = this.addAuthHeader(req, token);
          return next.handle(req);
        })
      );
    }
  }

  private handleError(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (error.status === 401) {
      return this.handleTokenRefresh(req, next);
    } else if (error.status === 403) {
      this.tokenService.routeToBase();
    } else {
      console.error('Request Error: ', error);
    }

    return throwError(error);
  }
}
