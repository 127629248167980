import { Injectable } from '@angular/core';
import { StorageHelper } from 'src/app/_utilities/storage-helper';

declare var mixpanel: any;

declare global {
  interface Window {
    Appcues: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ContentStudioTrackingService {

  private _storageHelper: StorageHelper;
  constructor(
  ) {
    this._storageHelper = new StorageHelper();
  }

  pageTrack(path: string) {
    try {
      mixpanel.track('Page Viewed', { page: path });
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        //throw e;
        console.log(e);
      }
    }
  }

  eventTrack(action: string, properties: any) {
    try {
      mixpanel.track(action, properties);

      //var pageViewData = {
      //    page: location.pathname,
      //    location: location.href,
      //    title: this.seoService.getTitle(),
      //    userId: loggedinUser.userName,
      //    isImpersonated: (loggedinUser.agencyId != 0 && loggedinUser.businessId == null) ? true : false,
      //};

      window.Appcues.track(action, properties);
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        //throw e;
        console.log(e);
      }
    }
  }

  setUsername(userId: string) {
    try {
      mixpanel.identify(userId);
      window.Appcues.identify(userId);
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        // throw e;
        console.log(e);
      }
    }
  }

  setUserProperties(properties) {
    try {
      window.Appcues.identify(
        properties.email, // unique, required
        {
          email: properties.email, // Current user's email
          role: properties.roleId,
          isAgencyUser: properties.agencyId != 0 && properties.businessId == null ? true : false,
        }
      );
      mixpanel.people.set({ Email: properties.email, Created: properties.createdDate, Last_login: new Date() });
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        //throw e;
        console.log(e);
      }
    }
  }

  setUserPropertiesOnce(properties: any) {
    try {
      mixpanel.people.set_once(properties);
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        //throw e;
        console.log(e);
      }
    }
  }

  setSuperProperties(properties: any) {
    try {
      mixpanel.register(properties);
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        //throw e;
        console.log(e);
      }
    }
  }

  setSuperPropertiesOnce(properties: any) {
    try {
      mixpanel.register_once(properties);
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        // throw e;
        console.log(e);
      }
    }
  }

  setAlias(alias: any) {
    try {
      mixpanel.alias(alias);
      //window.Appcues.alias(alias);
    } catch (e) {
      if (!(e instanceof ReferenceError)) {
        // throw e;
        console.log(e);
      }
    }
  }

  trackEvent(action: string, title:string) {
    var pageViewData = {
      page: location.pathname,
      location: location.href,
      isCMSUser: this._storageHelper.user.isCmsEnabled,
      businessName: this._storageHelper.user.businessName,
      tenantId: this._storageHelper.user.businessAlias,
      userId: this._storageHelper.user.userName,
      profileName: this._storageHelper.user.profileName,
      profileAlias: this._storageHelper.user.profileAlias,
      isImpersonated:false,
      title:title,
      businessId:this._storageHelper.user.bId
    };
    if (this._storageHelper.user.userName != undefined) {
      this.setUsername(this._storageHelper.user.userName);
      this.setAlias(this._storageHelper.user.userName);
      this.eventTrack(action, pageViewData);
    }
  }
}
