import {
  Component,
  OnInit,
  Output,
  Inject,
  EventEmitter,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AssetFilter,
  AssetTransformMeta,
  MscService,
} from 'msc';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { DamAssetModel } from '../../models/damassetmodel';
import { DamAssetInfo, ManualMeta } from '../../models/damassetinfo';
import { ImageTransformations } from '../../models/imagetransformations';
import { CmsService } from 'src/app/services/cms/cms.service';

@Component({
  selector: 'app-dam',
  templateUrl: './dam.component.html',
  styleUrls: ['./dam.component.scss'],
})
export class DamComponent implements OnInit {
  _storageHelper: StorageHelper;
  @Output() addAssets = new EventEmitter<any>();

  title = 'MSCApp';
  businessIds = [0];
  folderPathToFilter = '';
  maxAssetSelection = 1;
  damAssetList: DamAssetModel[] = [];
  assetBrowserUIConfig = {
    browserUI: {
      browserHeight: '90vh',
      showUpload: true,
      showCustomTransform: true,
    },

    transformUI: {
      showResize: false,
      showCrop: true,
      showRotate: true,
      cropperOptionsConfig: {
        cropBoxResizable: true,
        cropBoxMovable: true,
      },
    },
  };

  assetTransforms: AssetTransformMeta[] = [
    {
      transformIdentifier: 'Large',
      imageRenderingModel: {
        width: 0,
        height: 0,
        thumbnailDimensions: {
          height: 100,
          width: 100,
        },
      },
    },
    {
      transformIdentifier: 'Medium',
      imageRenderingModel: {
        width: 0,
        height: 0,
        thumbnailDimensions: {
          height: 100,
          width: 100,
        },
      },
    },
    {
      transformIdentifier: 'Small',
      imageRenderingModel: {
        width: 0,
        height: 0,
        thumbnailDimensions: {
          height: 100,
          width: 100,
        },
      },
    },
  ];

  resourceTypeId: number;

  assetFilters: AssetFilter[] = [];

  constructor(
    private mscService: MscService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cms: CmsService
  ) {
    this._storageHelper = new StorageHelper();
    this.mscService.baseUrl = this._storageHelper.user.damApiUrl;
  }

  ngOnInit() {
    this.setAssetTransforms();
    this.businessIds = this.data.businessIds;
    this.resourceTypeId = this.data.resourceTypeId;
    this.maxAssetSelection = this.data.maxAssetSelection;


    // // check if transformUITabConfig is passed - check in blog studio dam calling method
    // if (this.data.transformUITabConfig && this.data.transformUITabConfig.showOneTabInTransformUI) {
    //   // show only one tab in transform UI
    //   this.assetTransforms = [
    //     {
    //       transformIdentifier: this.data.transformUITabConfig.tabName && this.data.transformUITabConfig.tabName.length > 0 ? this.data.transformUITabConfig.tabName : "Large",
    //       imageRenderingModel: {
    //         width: 0,
    //         height: 0,
    //         thumbnailDimensions: {
    //           height: 100,
    //           width: 100,
    //         },
    //       },
    //     }
    //   ]
    // }

    //Create asset filter
    if (this.resourceTypeId) {
      let resourceTypeInfo: any = {};
      // await this.pageStudioService.getResourceTypeInfoById(
      //   this.resourceTypeId
      // );
      //console.log('Info:', resourceTypeInfo);
      if (
        resourceTypeInfo.resourceFileType === 'ImageFile' ||
        resourceTypeInfo.resourceFileType === 'Image File'
      ) {
        if (this.data.transformUITabConfig && this.data.transformUITabConfig.showOneTabInTransformUI) {
          //Large
          this.assetTransforms[0].imageRenderingModel.height =
            resourceTypeInfo.resourceHeight;
          this.assetTransforms[0].imageRenderingModel.width =
            resourceTypeInfo.resourceWidth;
        } else {
          //Large
          this.assetTransforms[0].imageRenderingModel.height =
            resourceTypeInfo.resourceHeight;
          this.assetTransforms[0].imageRenderingModel.width =
            resourceTypeInfo.resourceWidth;

          //Medium
          this.assetTransforms[1].imageRenderingModel.height =
            resourceTypeInfo.tabletResourceHeight;
          this.assetTransforms[1].imageRenderingModel.width =
            resourceTypeInfo.tabletResourceWidth;

          //Small
          this.assetTransforms[2].imageRenderingModel.height =
            resourceTypeInfo.mobileResourceHeight;
          this.assetTransforms[2].imageRenderingModel.width =
            resourceTypeInfo.mobileResourceWidth;
        }
        this.assetFilters.push({
          fieldName: 'assetType',
          value: 'image',
          comparison: 'eq',
        });

        this.assetFilters.push({
          fieldName: 'dimensions.height',
          value: resourceTypeInfo.resourceHeight?.toString(),
          comparison: 'gte',
        });

        this.assetFilters.push({
          fieldName: 'dimensions.width',
          value: resourceTypeInfo.resourceWidth?.toString(),
          comparison: 'gte',
        });
      } else if (resourceTypeInfo.resourceFileType === 'PDFFile') {
        this.assetFilters.push({
          fieldName: 'assetType',
          value: 'pdf',
          comparison: 'eq',
        });
      } else if (resourceTypeInfo.resourceFileType === 'TextFIle') {
        this.assetFilters.push({
          fieldName: 'assetType',
          value: 'text',
          comparison: 'eq',
        });
      } else if (resourceTypeInfo.resourceFileType === 'DocFile') {
        this.assetFilters.push({
          fieldName: 'assetType',
          value: 'doc',
          comparison: 'eq',
        });
      } else if (resourceTypeInfo.resourceFileType === 'XLSFile') {
        this.assetFilters.push({
          fieldName: 'assetType',
          value: 'excel',
          comparison: 'eq',
        });
      } else if (resourceTypeInfo.resourceFileType === 'XMLFile') {
        this.assetFilters.push({
          fieldName: 'assetType',
          value: 'xml',
          comparison: 'eq',
        });
      }
    }

  }

  setAssetTransforms() {
    this.assetTransforms = [
      {
        transformIdentifier: 'Large',
        imageRenderingModel: {
          width: this.data?.cmsSiteConfig?.blogImageDimensions?.desktopWidth || 0,
          height: this.data?.cmsSiteConfig?.blogImageDimensions?.desktopHeight || 0,
          thumbnailDimensions: {
            height: 100,
            width: 100,
          },
        },
      },
      {
        transformIdentifier: 'Medium',
        imageRenderingModel: {
          width: this.data?.cmsSiteConfig?.blogImageDimensions?.tabletWidth || 0,
          height: this.data?.cmsSiteConfig?.blogImageDimensions?.tabletHeight || 0,
          thumbnailDimensions: {
            height: 100,
            width: 100,
          },
        },
      },
      {
        transformIdentifier: 'Small',
        imageRenderingModel: {
          width: this.data?.cmsSiteConfig?.blogImageDimensions?.mobileWidth || 0,
          height: this.data?.cmsSiteConfig?.blogImageDimensions?.mobileHeight || 0,
          thumbnailDimensions: {
            height: 100,
            width: 100,
          },
        },
      },
    ];
  }

  selectdDamAssets(event) {
    this.updatedDamAssetObj(event);
    this.addAssets.emit(this.damAssetList);
  }

  updatedDamAssetObj(selectedAssets: any) {
    this.damAssetList = [];
    for (var i = 0; i < selectedAssets.length; i++) {
      try {
        let damAssetInfo = new DamAssetInfo();
        damAssetInfo.PreviewUrl = selectedAssets[i].assetPreviewUrl;
        damAssetInfo.ThumbnailUrl = selectedAssets[i].assetThumbnailUrl;
        damAssetInfo.OriginalUrl = selectedAssets[i].assetUrl;
        let imageTransformations = new ImageTransformations();
        let manualMeta = new ManualMeta();


        if (selectedAssets[i].manualMeta) {
          manualMeta.Description = selectedAssets[i].manualMeta.description
          manualMeta.Title = selectedAssets[i].manualMeta.title
          damAssetInfo.ManualMeta = manualMeta
        }

        if (
          selectedAssets[i].transforms != null &&
          selectedAssets[i].transforms.length > 0
        ) {
          var smallAssetObj = selectedAssets[i].transforms.filter(function (
            item
          ) {
            return item.transformIdentifier.toLowerCase() == 'small';
          });
          if (smallAssetObj != null && smallAssetObj.length > 0) {
            if (
              smallAssetObj[0]['transformedUrl'] != null &&
              smallAssetObj[0]['transformedUrl'] != undefined
            ) {
              damAssetInfo.SmallUrl = smallAssetObj[0]['transformedUrl'].url;
              damAssetInfo.ThumbnailUrl =
                smallAssetObj[0]['transformedUrl'].thumbnailUrl;
              imageTransformations.Small =
                smallAssetObj[0]['imageRenderingModel'];
            }
          }

          var mediumAssetObj = selectedAssets[i].transforms.filter(function (
            item
          ) {
            return item.transformIdentifier.toLowerCase() == 'medium';
          });
          if (mediumAssetObj != null && mediumAssetObj.length > 0) {
            if (
              mediumAssetObj[0]['transformedUrl'] != null &&
              mediumAssetObj[0]['transformedUrl'] != undefined
            ) {
              damAssetInfo.MediumUrl = mediumAssetObj[0]['transformedUrl'].url;
              damAssetInfo.ThumbnailUrl =
                mediumAssetObj[0]['transformedUrl'].thumbnailUrl;
              imageTransformations.Medium =
                mediumAssetObj[0]['imageRenderingModel'];
            }
          }

          var largeAssetObj = selectedAssets[i].transforms.filter(function (
            item
          ) {
            return item.transformIdentifier.toLowerCase() == 'large';
          });
          if (largeAssetObj != null && largeAssetObj.length > 0) {
            if (
              largeAssetObj[0]['transformedUrl'] != null &&
              largeAssetObj[0]['transformedUrl'] != undefined
            ) {
              damAssetInfo.LargeUrl = largeAssetObj[0]['transformedUrl'].url;
              damAssetInfo.ThumbnailUrl =
                largeAssetObj[0]['transformedUrl'].thumbnailUrl;
              imageTransformations.Large =
                largeAssetObj[0]['imageRenderingModel'];
            }
          }
          damAssetInfo.ImageTransformations = imageTransformations;
        }
        if (
          i != undefined &&
          selectedAssets[i] != null &&
          selectedAssets[i] != undefined
        )
          this.damAssetList.push(
            new DamAssetModel(
              selectedAssets[i].assetId,
              selectedAssets[i].assetName,
              selectedAssets[i].assetAlias,
              selectedAssets[i].folderId,
              selectedAssets[i].stockImage,
              damAssetInfo
            )
          );
      } catch (ex) {
        console.log(ex);
      }
    }
  }
}
