import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MwcMessageService } from 'mwc/message';
import { MwcModalRef, MwcModalService } from 'mwc/modal';
import { Subscription } from 'rxjs';
import { AddEditModalComponent } from 'src/app/manage-content/articles/add-edit-modal/add-edit-modal.component';
import { CreateNewModalComponent } from 'src/app/manage-content/articles/create-new-modal/create-new-modal.component';
import { ArticleAiGenerationLoadingModalComponent } from 'src/app/manage-content/articles/_shared/modals/article-ai-generation-loading-modal/article-ai-generation-loading-modal.component';
import { ArticleErrorModalComponent } from 'src/app/manage-content/articles/_shared/modals/article-error-modal/article-error-modal.component';
import { ArticleStoreService } from 'src/app/services/article/article-store.service';
import { ArticleService } from 'src/app/services/article/article.service';
import { ErrorModalOrigins } from 'src/app/_shared/consts/article-constants';
import { Article, CustomArticleErrorModel, GetArticleListRequest,  
  GetArticleListResponse, GetSEOWidgetResponse, PromptProperties, SaveCategoryRequest, SaveCategoryResponse, 
  Selector, SEOWidgetStoreStateType, Template } from 'src/app/_shared/models/article';
import { GetSettingsFileResponse } from 'src/app/_shared/models/getsettingsfileresponse';
import { HelperService } from '../helper.service';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { TopicIdeaPayloadForAIGeneration, TopicIdeaPayloadForAIGenerationType } from 'src/app/_shared/models/TopicIdeaPayloadForAIGeneration';
import { GenarateAiComponent } from 'src/app/genarate-ai-modal/genarate-ai.component';
import { ContentStudioTrackingService } from '../../tracking/contentstudio-tracking.service';
import { topicIdea } from '../../../models/topic';

@Injectable({
  providedIn: 'root'
})
export class ArticleHelperService {
_storageHelper:StorageHelper;
  saveCategorySubscription: Subscription;
  constructor(private articleService: ArticleService, private articleStoreService: ArticleStoreService, private helper: HelperService, private tracking:ContentStudioTrackingService) { 
    this._storageHelper = new StorageHelper();
  }
  
  // method is to save,update category
  addEditCategoryOkClicked(addEditInstance: AddEditModalComponent, callback: any) {
    if (addEditInstance && (addEditInstance.category.name == null ||
      addEditInstance.category.name == "" ||
      addEditInstance.category.name == undefined ||
      addEditInstance.category.name == " " ||
      addEditInstance.category.name.trim().length == 0)) {
        //addEditInstance.category.markAsTouched();
        callback({
          errorCode: 1,
          message: $localize`:@@bs-blog-studio-helper-service-add-edit-category-error-text:Category name should not be empty!`
        })
      return false;
    }
    // check if all things are not filled properly
    if (!addEditInstance.addEditForm.valid) {
      addEditInstance.addEditForm.markAsTouched();
      if(addEditInstance.addEditForm.get("metaDescription").value && addEditInstance.addEditForm.get("metaDescription").value.length > 160 ){
        callback({
          errorCode: 1,
          message: $localize`:@@bs-blog-studio-helper-service-add-edit-category-meta-description-error-text:Please ensure that the meta description has up to 160 characters only.`
        })
        return false;
      } else {
        callback({
          errorCode: 1,
          message: $localize`:@@bs-blog-studio-helper-service-add-edit-category-empty-error-text:Please fill all the fields`
        })
        return false;
      }
    }
    if (addEditInstance) {
      if (addEditInstance.category) {
        let blogCategorySaveRequest = new SaveCategoryRequest();
        blogCategorySaveRequest.categoryId = 0;
        if (addEditInstance.category.categoryId) {
          blogCategorySaveRequest.categoryId = addEditInstance.category.categoryId;
        }
        blogCategorySaveRequest.name = addEditInstance.category.name;
        blogCategorySaveRequest.metaDescription = addEditInstance.category.metaDescription;
        blogCategorySaveRequest.metaTitle = addEditInstance.category.metaTitle;
        blogCategorySaveRequest.slugUrl = addEditInstance.category.slugUrl;
        blogCategorySaveRequest.status = addEditInstance.category.status;
        blogCategorySaveRequest.profileId = this._storageHelper.user.pId;
        this.saveCategorySubscription = this.articleService.saveCategory<SaveCategoryResponse>(blogCategorySaveRequest).subscribe(
          (response) => {
            //callback with response;
            callback(response)
          }, (err) => {
            console.log(err);
            callback(err);
          }
        );
      }
    }
  }
  // for getting list of article 
  public getListOfArticles(callback: any, requestPayload: GetArticleListRequest = null) {
    let request = null;
    if (requestPayload != null) {
      request = requestPayload
    } else {
      request = new GetArticleListRequest();
      request.orderBy = "createddate";
      request.pageNumber = 1;
      request.profileId = this._storageHelper.user.pId;
      request.pageSize = 10;
      request.sortingOrder = "desc";
      request.selectors = [];
    }
    request.seoScoreRange = this.articleStoreService.getSEORange() === 0 ? null : this.articleStoreService.getSEORange();    
    this.articleService.getArticleList<GetArticleListResponse>(request).subscribe(
      (response) => {
        callback(response);
      },
      (error) => {
        callback(error);
      }
    );
  }

  public showErrorModal(modalService: MwcModalService, parentModalRef:MwcModalRef = null,  
    modalOrigin = ErrorModalOrigins.DEFAULT, exception: any = null, 
    customError: CustomArticleErrorModel = null, topicIdeaPayloadForAIGeneration: TopicIdeaPayloadForAIGenerationType = null) {
    let modalRef = modalService.create({
      mwcWidth: '544px',
      mwcContent: ArticleErrorModalComponent,
      mwcData: { modalOrigin, exception, customError, topicIdeaPayloadForAIGeneration},
      mwcFooter: null,
      mwcCentered: true
    });
    if (parentModalRef != null) {
      parentModalRef.destroy();
    }
    return modalRef;
  }

  public showAiGenerationLoadingModal(modalService: MwcModalService, parentModalRef:MwcModalRef = null,  
    modalOrigin = ErrorModalOrigins.DEFAULT) {
    let modalRef = modalService.create({
      mwcWidth:'310px',
      mwcContent: ArticleAiGenerationLoadingModalComponent,
      mwcData: { modalOrigin },
      mwcFooter: null,
      mwcCentered: true,
      mwcClosable: false,
      mwcMaskClosable: false
    });
    if (parentModalRef != null) {
      parentModalRef.destroy();
    }
    return modalRef;
  }

  createnewpost(mode: string, _router: Router, modalService: MwcModalService, settings: GetSettingsFileResponse = null, data: topicIdea = null,templateData:Template = null, inputText: string = null) {
    if(mode == "mannual"){
      this.tracking.trackEvent('pageview','Generic Flow - Manual');
      let data = new Article();
      data.language = settings?.language ? settings.language: "";
      let promptProperties = new PromptProperties();
      data.promptProperties = promptProperties;
      data.promptProperties.isManualGeneration = "true";
      this.articleStoreService.updateState(data);
      _router.navigateByUrl("/manage-content/articles/create-detailed-post/create");
    }
    if (mode == "AI" && data == null) {
      this.tracking.trackEvent('pageview','Generic Flow - Generate with AI');
      let topicIdeaPayloadForAIGeneration = new TopicIdeaPayloadForAIGeneration();
      // topicIdeaPayloadForAIGeneration.topicIdea = data;
      topicIdeaPayloadForAIGeneration.language = settings?.language ? settings.language : "";
      topicIdeaPayloadForAIGeneration.tone = settings?.tone ? settings.tone : "";
      topicIdeaPayloadForAIGeneration.gender = settings?.defaultGender ? settings?.defaultGender : "";
      topicIdeaPayloadForAIGeneration.ageGroup = settings?.defaultAgeGroup ? settings?.defaultAgeGroup : "";
      topicIdeaPayloadForAIGeneration.interestAndPersona = settings?.interestAndPersona ? settings?.interestAndPersona : "";
      topicIdeaPayloadForAIGeneration.brandVoiceDocument = settings?.brandVoiceDocument;
      topicIdeaPayloadForAIGeneration.isGenericFlow = true;
      topicIdeaPayloadForAIGeneration.templateId = templateData?.templateId;
      topicIdeaPayloadForAIGeneration.templateTitle = templateData?.title;
      topicIdeaPayloadForAIGeneration.inputText = inputText;
      topicIdeaPayloadForAIGeneration.titleText = templateData.titleText;
      topicIdeaPayloadForAIGeneration.placeholderText = templateData.placeholderText;
      topicIdeaPayloadForAIGeneration.contentBriefText = templateData.contentBriefText;
      topicIdeaPayloadForAIGeneration.isPublishable = templateData.isPublishable;
      modalService.create({
        mwcData: {
          AIData: topicIdeaPayloadForAIGeneration
        },
        mwcWidth: '800px',
        mwcCentered: true,
        mwcContent: GenarateAiComponent,
        mwcClassName: 'generate-ai-modal'
      });
      // modalService.create({
      //   mwcData: {
      //     description: '',
      //     tone: this.helper.makeFirstLetterCapital(settings?.tone? settings.tone : "Informative"),
      //     language: settings?.language ? settings.language : "US"
      //   },
      //   mwcWidth: '640px',
      //   mwcContent: CreateNewModalComponent
      // });
    }
    else {
      this.tracking.trackEvent('pageview','Content Intelligence Flow - Generate on Tile/Card');
      let topicIdeaPayloadForAIGeneration = new TopicIdeaPayloadForAIGeneration();
      topicIdeaPayloadForAIGeneration.topicIdea = data;
      topicIdeaPayloadForAIGeneration.language = settings?.language ? settings.language : "";
      topicIdeaPayloadForAIGeneration.tone = settings?.tone ? settings.tone : "";
      topicIdeaPayloadForAIGeneration.gender = settings?.defaultGender ? settings?.defaultGender : "";
      topicIdeaPayloadForAIGeneration.ageGroup = settings?.defaultAgeGroup ? settings?.defaultAgeGroup : "";
      topicIdeaPayloadForAIGeneration.interestAndPersona = settings?.interestAndPersona ? settings?.interestAndPersona : "";
      topicIdeaPayloadForAIGeneration.brandVoiceDocument = settings?.brandVoiceDocument;
      topicIdeaPayloadForAIGeneration.isGenericFlow = false;
      topicIdeaPayloadForAIGeneration.templateId = templateData?.templateId;
      topicIdeaPayloadForAIGeneration.templateTitle = templateData?.title;
      topicIdeaPayloadForAIGeneration.inputText = data?.pillarIdea;
      topicIdeaPayloadForAIGeneration.titleText = templateData.titleText;
      topicIdeaPayloadForAIGeneration.placeholderText = templateData.placeholderText;
      topicIdeaPayloadForAIGeneration.contentBriefText = templateData.contentBriefText;
      topicIdeaPayloadForAIGeneration.isPublishable = templateData.isPublishable;
      modalService.create({
        mwcData: {
          AIData: topicIdeaPayloadForAIGeneration
        },
        mwcWidth: '800px',
        mwcCentered: true,
        mwcContent: GenarateAiComponent,
        mwcClassName: 'generate-ai-modal'
      });
    }
  }

  public getSEOWidgetScoreData(profileId: number, callback: any) {
    this.articleService.getSEOWidget<GetSEOWidgetResponse>(profileId).subscribe(
      (response) => {
        if (response) {
          let seoWidgetData: SEOWidgetStoreStateType = {
            totalBlogCount: 0,
            overallScore: 0,
            poorCount: 0,
            goodCount: 0,
            averageCount: 0
          }
          seoWidgetData.totalBlogCount = response.totalArticles;
          seoWidgetData.overallScore = response.averageSEOScore;
          if (response.seoScore) {
            response.seoScore.forEach((item, index) => {
              if (item.seoLabel == "Poor") {
                seoWidgetData.poorCount = Number(item.noOfPosts);
              } else if (item.seoLabel == "Good") {
                seoWidgetData.goodCount = Number(item.noOfPosts);
              } else {
                seoWidgetData.averageCount = Number(item.noOfPosts);
              }
            });
          }
          this.articleStoreService.updateSEOWidgetState(seoWidgetData);
        }
        callback(response);
      });
  }
  public concatenateContentStudioData(article: any) {
    // concatenate all the data here
    let concatenatedValue = article.title
      + article.content
      + (article.damImage ? article.damImage.assetId ? article.damImage.assetId : article.damImage.AssetId : "")
      + article.imageAltText
      + article.keyPhrase
      + article.snippet
      + article.module
      + article.slugUrl
      + article.metaTitle
      + article.metaDescription
      + article.authorName
      + article.authorBiolink
      + (article.categoryList ? article.categoryList.join('') : "")
      + (article.tagsList ? article.tagsList.join('') : "")
    
    return concatenatedValue
  }

}
