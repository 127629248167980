export const enum CheckListSection {
    PROBLEM = "RED",
    IMPROVMENT = "AMBER",
    GOOD = "GREEN"
}

export enum SEOChecklistTitle {
    SET_A_FOCUS_KEYPHRASE = 1,
    USE_KEYPHRASE_IN_POST_TITLE = 2,
    WORD_COUNT_IN_BODY = 3,
    INSERT_2_IMAGES = 4,
    CHECK_ALT_TAGS = 5,
    INSERT_2_SUBHEADERS = 6,
    KEYPHRASE_IN_SUBHEADER = 7,
    KEYPHRASE_IN_POST_BODY = 8,
    USE_KEYPHRASE_IN_META_TITLE = 9,
    META_TITLE_LENGTH = 10,
    USE_KEYPRHASE_IN_META_DESCRIPTION = 11,
    META_DESCRIPTION_LENGTH = 12,
    USE_KEYPHRASE_IN_URL = 13,
    INSERT_OUTBOUND_LINK = 14,
    INSERT_INBOUND_LINK = 15
}

export enum IndividualFieldIDForAI {
    META_DESCRIPTION = "GENERATE_BLOG_META_DESCRIPTION",
    META_TITLE = "GENERATE_BLOG_META_TITLE",
    BLOG_TITLE = "GENERATE_BLOG_TITLE",
    EXCERPT_SUMMARY = "GENERATE_BLOG_EXCERPT_SUMMARY",
    URL_SLUG = "GENERATE_BLOG_URL_SLUG",
    META_DESCRIPTION_FOR_CATEGORY = "GENERATE_CATEGORY_META_DESCRIPTION"
}

export enum GKPToolTips {
    KEYPHRASE_TOOLTIP = "The specific word or phrase that you are targeting in your content and for which you want to rank organically.",
    SEARCH_VOLUME_TOOLTIP = "The number of times a keyphrase is searched for on search engines within month.",
    TREND_TOOLTIP = "The relative popularity of a keyphrase over time. Helps you identify whether a keyphrase is gaining or losing trend.",
    COMPETITION_TOOLTIP = "Provides insights into the competitiveness of a keyphrase, helping you gauge the difficulty of ranking for it. Higher competition may require more effort to achieve visibility."
}

export enum ArticleHttpTimeouts {
    GENERATE_KEYPHRASE_TIMEOUT = (100 * 1000), // 100 seconds converted into milliseconds  
    GKP_TIMEOUT = (100 * 1000),
    GENERATE_INDIVIDUAL_FIELD_TIMEOUT = (100 * 1000),
    GENERATE_POSTBODY_TIMEOUT = (100 * 1000),
    GENERATE_POSTBODY_FORTOPICIDEA_TIMEOUT = (180 * 1000),
    GENERATE_BRANDVOICE_DOCUMENT_TIMEOUT = (100 * 1000),
    GENERATE_ARTICLE_FAQS_TIMEOUT = (100 * 1000)
}

export enum ErrorModalOrigins {
    DEFAULT = 0,
    FROM_KEYPHRASE_GENERATION_MODAL = 1,
    FROM_GKP_MODAL = 2,
    FROM_TOPIC_IDEA_AI_GENERATION_MODAL = 3,
    FROM_TOPIC_IDEA_AI_OUTLINE_GENERATION_MODAL = 4
}

export enum SEOFilterOptions {
    ALL = 0,
    POOR = 1,
    AVERAGE = 2,
    GOOD = 3
}
export class SEOFilterRange {
    public static readonly POOR = {
        From: 0,
        To: 49
    };
    public static readonly AVERAGE = {
        From: 50,
        To: 74
    };
    public static readonly GOOD = {
        From: 75,
        To: 100
    };
}

export enum ArticleListTableTabs {
    ALL = 'all',
    SCHEDULED = 'scheduled',
    TRASH = 'trash',
    DELETE = 'delete',
    DRAFT = 'draft',
    PUBLISHED = 'published',
    RESTORE = 'restore'
}

export enum TestingListTableTabs {
    ALL = 'all',
    ACTIVE = 'Active',
    COMPLETED = 'Completed',
    TRASH = 'Trash'
}

export enum ArticleStatus {
    DRAFT = 1,
    PUBLISHED = 2,
    DELETED = 3,
    RESTORED = 4
}

export const SupportedImageExtensions = [".jpg", ".jpeg", ".png"];

export enum CreativeControls {
    EXPAND = "EXPAND",
    PARAPHRASE = "PARAPHRASE",
    CHANGE_VOICE = "CHANGE_VOICE",
    CUSTOM_COMMAND = "CUSTOM_COMMAND",
}

export enum KeywordConstraint {
    MAX_KEYWORDS = 6
}

export namespace FAQ {
  export enum AuthorType {
    MERCHANT = 'MERCHANT'
  }
    export enum Type {
        FAQ = '_faq'
    }
    export enum DataSource {
      Milestone = '_milestone',
      WebsiteNonCms = '_website_non_cms',
      WebsiteMs = '_website_ms'
    }
    export enum OriginDataSource {
        ContentStudio = '_contentStudio'
    }
    export enum Category {
        None = 'None'
    }
  export enum Status {
    New ='_new',
      Draft = '_draft',
      SendToPublish = '_sendToPublish',
      Published = '_published'
    }
    export enum FaqGeneratorType {
        Type = '_article'
    }
    export enum Origin {
        ContentStudio = '_contentStudio'
    }
    export enum FAQOrigin {
        Manual = '_manual',
        AI = '_ai'
    }
}
