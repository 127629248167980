import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MwcDrawerRef } from 'mwc/drawer';
import { Observable } from 'rxjs';
import { HelperService } from 'src/app/_shared/services/helper/helper.service';
import { StorageHelper } from 'src/app/_utilities/storage-helper';



@Injectable({
  providedIn: 'root',
})
export class CommonService {
  // Private Properties  
  private drawerRef: MwcDrawerRef<any, any>[] = [];

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private _storageHelper: StorageHelper;
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private helperService: HelperService,   
  ) {
    this._storageHelper = new StorageHelper();
  }

  setDrawerRef(drawerRef: MwcDrawerRef<any, any>) {
    this.drawerRef.push(drawerRef);
  }

  destroyDrawerRef() {
    if (this.drawerRef) {
      this.drawerRef.forEach((ref: MwcDrawerRef) => {
        ref.close();
      });
      this.drawerRef = [];
    }
  }


}
