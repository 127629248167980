import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, TimeoutError } from 'rxjs';
import { StorageHelper } from 'src/app/_utilities/storage-helper';
import { CommonService } from '../../services/common/common.service';
import { SlugifyPipe } from '../../_shared/pipes/slugify.pipe';
import { ArticleService } from 'src/app/services/article/article.service';
import { GetProfileListResponse, profileDetail } from 'src/app/_shared/models/article';
import { TemplateHelperService } from 'src/app/_shared/services/helper/template-selector/template-helper.service';
@Component({
  selector: 'ms-dpr-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BasicLayoutComponent implements OnInit {
  isCollapsed = false;
  isContentIntelEnable: boolean = false;
  isContentGenEnabled: boolean = false;

  getLeftMenuSubscription: Subscription;
  _storageHelper: StorageHelper;
  currentUrl: string = location.href;
  selectedProfileName: string = "";
  returnUrlToMPC: string = "";
  selectedLanguage: string = "en";
  openMap: { [name: string]: boolean } = {
    sub1: false,
    sub2: true,
    sub3: false
  };
  iconsList = [
    { oldName: 'Dashboard', newName: 'home-line' },
    { oldName: 'Marketing Tools', newName: 'line-chart-up-01' },
    { oldName: 'Manage Content', newName: 'layers-three-01' },
    { oldName: 'Manage Layout', newName: 'layers-three-01' },
    { oldName: 'Settings', newName: 'settings-01' },
    { oldName: 'SEO', newName: 'file-search-02' },
    { oldName: 'Bulk Operations', newName: 'copy-06' },
    { oldName: 'Manage Users', newName: 'users-01' },
    { oldName: 'Guest Portal', newName: 'globe-02' },
    { oldName: 'MiBlock', newName: 'layers-two-01' },
    { oldName: 'Newsroom', newName: 'announcement-02' },
    { oldName: 'Milestone Assets', newName: 'folder' },
  ];
  visible = false;
  selectedProfileId: any = '';
  profiles: profileDetail[] = [];
  filteredProfiles: profileDetail[] = [];
  isDamEnabled = false;
  dprBaseUrl = "";
  openHandler(value: string): void {
    for (const key in this.openMap) {
      if (key !== value) {
        this.openMap[key] = false;
      }
    }
  }
  constructor(
    private cdRef: ChangeDetectorRef,
    private commonService: CommonService,
    private router: Router,
    private articleService: ArticleService,
    private templateHelperService: TemplateHelperService,
  ) {
    this._storageHelper = new StorageHelper();
  }
  closeDropdown(value) {
    this.visible = value;
  }
  ngOnInit(): void {
    this.selectedProfileName = this._storageHelper.user.profileName;
    this.selectedLanguage = this._storageHelper.UserLanguage;
    this.returnUrlToMPC = this._storageHelper.user.returnUrl;
    this.isDamEnabled = this._storageHelper.user.isDamEnabled;
    this.dprBaseUrl = this._storageHelper.user.dprBaseUrl;
    //const filterPipe = new SlugifyPipe();
    //const fiteredArr = filterPipe.transform(this.blogCategory.slugUrl);
    //console.log(fiteredArr);
    this.isContentIntelEnable = this._storageHelper.user.isContentIntelEnabled;
    this.isContentGenEnabled = this._storageHelper.user.isContentGenEnabled;
    this.getProfileList();
    this.returnUrlToMPC = this._storageHelper.user.returnUrl;
  }
  getProfileList() {
    this.articleService.profileList = null;
    this.articleService.getProfileList().subscribe((response: GetProfileListResponse) => {
      if (response.errorCode == 0) {
        if (response.profiles && response.profiles.length > 0) {
          this.articleService.profileList = response.profiles;
          this.profiles = response.profiles;
          this.filteredProfiles = response.profiles;
          this.selectedProfileId = this._storageHelper.user.pId;
          if(response.profiles.filter(p=>p.profileId === this._storageHelper.user.pId).length > 0){
            const selectedProfileId = response.profiles.filter(p=>p.profileId === this._storageHelper.user.pId)[0];
            this.articleService.IsContentIntelligenceEnabled = selectedProfileId.featureIds.includes("_con_content_intelligence");
          }
        }
      } else {
        //this.msg.error(response.message);
      }
      // using angular change detection here because angular won't detect due to  callback
      this.cdRef.detectChanges();
    }, (error) => {
      if (error instanceof TimeoutError) {
        //this.msg.error(error.message + ". Please try again");
        this.cdRef.detectChanges();
      } else {
        if (error && typeof error.error == "string") {
          //this.msg.error(error.message);
        } else {
          (error.error.errors as string[]).forEach((item) => {
            //this.msg.error(item);
          })
        }
        this.cdRef.detectChanges();
      }
    });
  }
  updateIcon(iconName: string) {
    //console.log(iconName);
    //console.log(this.iconsList.find(cn => cn.oldName === iconName).newName ?? '');
    return this.iconsList.find(cn => cn.oldName === iconName)?.newName ?? '';
  }

  initTemplate() {
    this.templateHelperService.openTemplateSelector();
  }

  languageChanged(event: string) {
    // store preference in local storage
    this._storageHelper.UserLanguage = event;
    window.location.reload();
  }

  public openKnowledgeBase() {
    window.open("https://milestone-inc.helpjuice.com/137421-what-s-new", "_blank");
  }
  public openPromptRepository() {
    window.open("https://milestone-inc.helpjuice.com/content-generator-use-cases-prompt-guide-", "_blank");
  }
  isMenuSelected(routerLink: string) {
    //debugger;
    return this.router.url == routerLink;
  }
  mpcRedirect() {
    if (this.returnUrlToMPC !== '')
      window.open(this.returnUrlToMPC, "_blank");
  }

  dprRedirect() {
    window.open(this.dprBaseUrl, "_blank");
  }

  assetLibraryRedirect() {
    if (this.returnUrlToMPC !== '') {
      window.open(this.returnUrlToMPC + "/dam/folderlist", "_blank");
    }
  }
}
